@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  overflow-x: hidden !important;
}
.test-css {
  font-size: 50px;
  color: blueviolet;
}
.bg-white {
  background-color: #2fb6d4 !important;
}
.navbar-light .navbar-nav .nav-link.active {
  color: rgb(255 255 255 / 90%);
  background-color: #187e94;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.text-primary {
  color: #fff !important;
}
.text-purple {
  color: #fff !important;
}
.text-success {
  color: #fff !important;
}
.text-orange {
  color: #fff !important;
}
.text-pink {
  color: #fff !important;
}
.text-default {
  color: #fff !important;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  background-color: #187e94;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #fff;
}
.bg-gradient-danger {
  background: #e8fbff !important;
  /*background-color: #fafafa !important;*/
}
.mt--9,
.my--9 {
  margin-top: -18.5rem !important;
}
.py-7 {
  padding-bottom: 15rem !important;
}
.main-content .navbar-top {
  background-color: #ffffff;
  box-shadow: 5px -3px 10px #888888;
}
.text-white {
  color: #919191 !important;
}
.icon-shape i,
.icon-shape svg {
  font-size: 1.25rem;
  color: #fff;
}
.text-muted {
  color: #000;
}
.text-copirits {
  color: #8898aa !important;
}
.text-successing {
  color: #2dce89;
}

.navbar-vertical.navbar-expand-md {
  z-index: 99;
}
body {
  background-color: #e8fbff;
}
.nav-link i.ni {
  margin-right: -10px;
  font-size: 25px;
  color: #2cb6d2;
}
.belling {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
  margin-right: -11px;
}
.notifik {
  margin-right: 13px;
  font-size: 21px;
  margin-top: -2px;
}
.vigible a.text-purple {
  color: #5a2acf !important;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #2cb6d2;
  border: 1px solid #2cb6d2;
}
a.text-purple,
a.text-purple:focus {
  color: #2cb6d2 !important;
}
a.text-purple:hover {
  color: #187e94 !important;
}
.btn-danger:hover {
  color: #fff;
  background-color: #187e94;
  border-color: #187e94;
}
.btn btn-danger {
  background-color: #2fb6d4 !important;
  border-color: #2fb6d4 !important;
}
.capwhite {
  color: #fff !important;
}
.capwhite:hover {
  color: #fff !important;
}
ul.breadcrumb {
  padding: 10px 0px;
  list-style: none;
  background-color: transparent !important;
}

ul.breadcrumb li {
  display: inline;
  font-size: 13px;
}

ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

ul.breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}

.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}
.usrdtls {
  background-color: #11cdef !important;
}
.edback {
  background-color: #f8f9fe !important;
}
.bokwhit,
.bokwhit:hover {
  color: #fff;
}
.comunity_manage {
  font-size: 18px;
  color: #32325d;
  font-weight: 500;
}

.footer {
  background: #f7fafc;
  padding: 10px 0px;
  /* margin-top: 10px; */
  /* margin-bottom: 0px; */
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #11cdef;
  border-color: #11cdef;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #11cdef;
}
.nav-pills .nav-link:hover {
  color: #11cdef;
}
.nav-pills .nav-link {
  color: #11cdef !important;
}
.footer {
  padding: 0.7rem 0;
}
.setbtn {
  background-color: #093683;
}
.input-group-prepend {
  background-color: #2fb6d4;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #187e94;
}
.btn-secondary {
  background-color: #187e94;
}
.avatar {
  background-color: transparent;
}
.btn-danger,
.btn-danger:hover {
  background-color: #2cb6d2 !important;
  border-color: #2cb6d2 !important;
}
.d-btn {
  background-color: #2cb6d2 !important;
  color: #fff !important;
}
.input-group .form-control:not(:first-child) {
  color: #000;
  padding-left: 10px;
}
.mood_table {
  text-align: center;
}
.box_wrapper {
  background-color: #fff;
  margin: 0 auto;
  width: 70%;
  border-radius: 5px;
  height: auto;
}
.tool_test {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
}
form i.fa-search {
  font-size: initial;
  color: #fff;
}
.text-green {
  color: green;
}
.navbar-vertical.navbar-expand-md.fixed-left + .main-content {
  min-height: 100vh;
  padding-bottom: 90px;
}
/*tool management*/
.mod_box {
  background-color: #fff;
  margin-top: 30px;
  border-radius: 0.375rem;
  margin-left: 39px !important;
  margin-right: 39px !important;
}
.no_margin_mood_box {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mod_testi {
  padding: 19px 0pc 3px 14px;
  margin-top: 10px;
}
.mod_box td,
.table .mood_table th {
  vertical-align: middle;
}

/*journal templates*/
.wrapper {
  width: 430px;
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.05);
}
.wrapper header {
  color: #6990f2;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}
.wrapper form {
  height: 167px;
  display: flex;
  cursor: pointer;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed #6990f2;
}
form :where(i, p) {
  color: #6990f2;
}
form i {
  font-size: 50px;
}
form p {
  margin-top: 15px;
  font-size: 16px;
}

section .row {
  margin-bottom: 10px;
  background: #e9f0ff;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section .row i {
  color: #6990f2;
  font-size: 30px;
}
section .details span {
  font-size: 14px;
}
.progress-area .row .content {
  width: 100%;
  margin-left: 15px;
}
.progress-area .details {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: space-between;
}
.progress-area .content .progress-bar {
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 30px;
}
.content .progress-bar .progress {
  height: 100%;
  width: 0%;
  background: #6990f2;
  border-radius: inherit;
}
.uploaded-area {
  max-height: 232px;
  overflow-y: scroll;
}
.uploaded-area.onprogress {
  max-height: 150px;
}
.uploaded-area::-webkit-scrollbar {
  width: 0px;
}
.uploaded-area .row .content {
  display: flex;
  align-items: center;
}
.uploaded-area .row .details {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}
.uploaded-area .row .details .size {
  color: #404040;
  font-size: 11px;
}
.uploaded-area i.fa-check {
  font-size: 16px;
}
.difrnt {
  background-color: #187e94;
  color: #fff;
}
.addrol_outraping {
  margin-top: 10px;
}
.addrole_outer {
  background-color: #187e94;
  /* border-top-left-radius: 14px; */
  /* border-top-right-radius: 14px; */
}
.text-fff {
  color: white;
}
.addrole_top p {
  color: #fff;
  text-align: left;
  font-family: "Poppins";
  padding: 10px 0px 1px 12px;
  font-weight: 550;
  font-size: 18px;
}
.addrole_outer {
  background-color: #187e94;
  border-radius: 8px;
  /* padding-bottom: 2px; */
}
.first_frm {
  padding-top: 17px;
  margin-top: 17px;
}
.first_frm label {
  font-weight: 600;
  font-size: 17px;
}
.labl_infore {
  background-color: #fff;
}
.lbl_boxcing {
  border: 1px solid #dde3e7;
  border-radius: 9px;
  padding: 8px 9px 2px;
}
.seprt_boc {
  background-color: #f8f8fa;
}
.lbl_boxcingin {
  border: 1px solid #dde3e7;
  padding: 8px 9px 2px;
}
.lbl_boxcingbor {
  border: 1px solid #dde3e7;
  padding: 8px 9px 2px;
  border-radius: none;
}
.inbuilt {
  width: 32.37%;
}
.blok_ones {
  margin-left: 16px;
}
.leftsift {
  margin-left: 15px;
}
.bdn_cover {
  display: block;
  width: 100%;
  /* margin: 10px 0px; */
  text-align: center;
  background: #fff;
}
.brn-topwrp {
  width: 100%;
  background: #fff !important;
  display: block;
  margin-top: 42px;
}
.try_whole {
  background: #fff;
  border-radius: 7px;
}
.brn-botwrp {
  width: 100%;
  background: #fff !important;
  display: block;
  margin-bottom: 42px;
}
.sometxt {
  font-weight: 600;
  color: #000;
  font-size: 14px;
}
.bdn_cover .btn-info {
  color: #fff;
}
.bdn_cover .difrnt {
  color: #fff;
}
.edity {
  margin-right: 10px;
}
.difrnt:hover {
  color: #fff;
}
.text-blue {
  color: #fff !important;
}
a.text-white,
a.text-white:hover,
a.text-white:focus {
  color: #d9d9d9 !important;
  background-color: #2cb6d2 !important;
  color: #fff !important;
}
.btn-secondary {
  background-color: #187e94;
  color: #fff;
}
.btn-secondary:hover {
  background-color: #187e94;
  color: #fff;
}

.journl-inner {
  background-color: #fff;
  border-radius: 10px;
}
.journl_raper {
  background-color: #f5ecf8;
}
.inner_boxfst {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.inner_fstright textarea {
  width: 100%;
  font-family: "Poppins", sans-serif;
}
.inner_fstleft input {
  width: 100%;
}
.sumitt {
  float: right;
}
.top_fst {
  margin: 16px 0px;
}
.inner_fstright label {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-bottom: 1px;
  color: #000;
}
.inner_fstright input {
  overflow: visible;
  border: none;
  font-family: "Poppins";
}
.sumitt {
  margin-top: 29px;
  background-color: #187e94;
  color: #fff;
  padding: 6px 5px;
  border-radius: 5px;
}
.glry .fa {
  font-weight: 900;
  color: #187e94;
  margin-right: 15px;
  font-size: 19px;
}
.glry {
  width: 78%;
  margin-top: 31px;
}
.inner_fstleft img {
  margin-top: 26px;
}
.seprt_sec {
  margin-top: 15px;
}
.qsn_allwrap h1,
p,
h3 {
  color: #fff;
}
.capdtl {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
  font-size: 1.0625rem;
}
.adrol {
  margin-bottom: 13px;
  float: right;
  margin-right: 50px;
}
.adbrdkrm .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  color: #fff;
  border-radius: 0.375rem;
  float: right;
  font-size: 14px;
  font-weight: 600;
  padding-top: 17px;
  margin-bottom: 13px;
}
.adbrdkrm .breadcrumb li a,
.adbrdkrm .breadcrumb li a:hover,
.adbrdkrm .breadcrumb-item.active {
  color: #313131;
  text-decoration: none;
}
.adbrdkrm .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #313131;
  content: "/";
}
.addrole_top p {
  padding-top: 15px;
}
.addrole_tophead p {
  color: #000000de;
  font-size: 17px;
  font-weight: bolder;
  text-transform: uppercase;
  padding-top: 13px;
}
.addrole_tophead h6 {
  margin-top: -17px;
  font-size: 13px;
  color: #32325d;
  margin-left: 1px;
}
.mangrol {
  float: right;
  margin-right: 13px;
}
.avatar-sm {
  margin-top: -7px;
}

.alayticks_inner {
  width: 100%;
  margin: 136px auto 0;
  background-color: #fff;
  border-radius: 5px;
  /* height:350px */
}
.alayticks_heading h1 {
  color: #919191;
  padding: 10px 30px 0px 30px;
  font-size: 19px;
}
.alayticks_middle h1 {
  padding-top: 110px;
}
.input-group-text {
  background-color: #2fb6d4;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  color: #187e94;
}
.navbar-search .input-group .input-group-text {
  background-color: none !important;
}
.navbar-search .input-group .input-group-text {
  border-bottom-left-radius: 0px;
  background-color: #2fb6d4;
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  color: #187e94;
  float: right;
  border-top-left-radius: 0px;
}
.card-profile-image img {
  max-width: 110px;
}
.jounl-main {
  margin-top: 20px;
}
.propass_update {
  padding-top: 24px;
}
.tobylogn {
  color: #fff !important;
}
.tobyragistr {
  color: #fff;
  background-color: #2fb6d4;
  border-color: #2fb6d4;
}
.tobyragistr:hover {
  color: #fff;
  background-color: #187e94;
  border-color: #187e94;
}
.txtoby a {
  color: #187e94;
}
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #187e94;
  border-color: #187e94;
}
.adcomuniti {
  float: right !important;
  margin-right: 6px;
}

.second_header12 {
  width: 100%;
  height: 100px;
  /* margin-bottom: 103px; */
}
.colpsebrnd img {
  margin-left: 5.5rem !important;
  margin-top: 16px;
}
.adhedrol h4 {
  color: #5c5c5c;
  margin-top: 20px;
  font-size: 21px;
  font-weight: 700;
}

.adhedrol h4 {
  color: #5c5c5c;
}
.bothorizon {
  border-bottom: 2px solid #5c5c5c;
  clear: both;
  width: 100%;
  margin-top: 20px;
}
.adpoldrop {
  margin-top: 21px;
}
.signni {
  float: right;
  margin-top: -15px;
}

/* width */
#sidenav-main::-webkit-scrollbar {
  width: 7px;
}

/* Track */
#sidenav-main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#sidenav-main::-webkit-scrollbar-thumb {
  background: #187e94;
  border-radius: 20px;
}

/* Handle on hover */
#sidenav-main::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.navbar-search .input-group {
  border: 0px solid;
}
iframe {
  display: none;
}
